export const baseUrl = "https://mylivedodo.pro/api";

export const apiUrl = {
  login: `${baseUrl}/dodo/workspace/login`,
  history: `${baseUrl}/dodo/my/upload/histories`,
  my_files: `${baseUrl}/dodo/my/uploads`,
  my_employees: `${baseUrl}/my/employees`,
  upload_file: `${baseUrl}/dodo/uploads`,
  upload_file_users: `${baseUrl}/dodo/upload`,
};
