import { Spin } from "antd";
import React from "react";

export default function PageLoading() {
  return (
    <div className="flex items-center justify-center h-screen">
      <Spin size="large" />
    </div>
  );
}
