import { Spin } from "antd";
import React, { Suspense } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import PageLoading from "./components/spinners/PageLoading";
import OutletWithProvider from "./context/OutletWithProvider";
import PrivateRoute from "./components/hoc/PrivateRoute";

const PageNotFound = React.lazy(() => import("./pages/auth/PageNotFound"));
const Wrapper = React.lazy(() => import("./pages/auth/Wrapper"));
const Login = React.lazy(() => import("./pages/auth/Login"));
const History = React.lazy(() => import("./pages/history/History"));
const Files = React.lazy(() => import("./pages/files/Files"));

function App() {
  return (
    <Suspense fallback={<PageLoading />}>
      <Router>
        <Routes>
          <Route path="/*" element={<PageNotFound />} />
          <Route path="/" element={<Wrapper />} />
          <Route path="/signin" element={<Login />} />

          <Route element={<PrivateRoute />}>
            <Route element={<OutletWithProvider />}>
              <Route path="/history" element={<History />} />
              <Route path="/files" element={<Files />} />
            </Route>
          </Route>
        </Routes>
      </Router>

      <ToastContainer />
    </Suspense>
  );
}

export default App;
